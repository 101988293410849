#components-form-demo-normal-login .login-form {
  max-width: 300px;
}

#components-form-demo-normal-login .login-form-forgot {
  float: right;
}

#components-form-demo-normal-login .ant-col-rtl .login-form-forgot {
  float: left;
}

#components-form-demo-normal-login .login-form-button {
  width: 100%;
}

.ant-input,
.ant-select .ant-select-selector,
.ant-input-number,
.ant-input-affix-wrapper {
  border-radius: 6px;
}

.ant-card {
  border-radius: 6px;
  margin-bottom: 10px !important;
}

.ant-table-pagination.ant-pagination {
  margin: 16px 10px !important;
}

.ant-btn {
  border-radius: 6px;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 6px 10px !important;
  word-break: inherit !important;
}

.ant-form-item {
  margin-bottom: 12px !important;
}

.ant-table {
  overflow-x: scroll !important;
}

.ant-form-item-control {
  line-height: 30px;
}

.ant-list-item .ant-row {
  width: 100%;
}

/*mobile View*/
@media (max-width: 575px) {
  #components-form-demo-normal-login .login-form-forgot {
    padding: 0;
  }
}

#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #455a64 !important;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #021529 !important;
}

.ant-h2 {
  font-size: 24px;
  font-weight: 500;
}

.ant-paddingLeftZero {
  padding-left: 0px !important;
}

.ant-radio-button-wrapper {
  height: 100% !important;
}

.editable-row .ant-form-item-explain {
  position: absolute;
  top: 100%;
  font-size: 12px;
}

.column_width {
  max-width: 300 !important;
}

.ant-c-popover .ant-popover-inner-content {
  left: 25% !important;
  max-width: 70% !important;
}

.ant-form-item .ant-input-textarea-show-count:after {
  margin-bottom: -16px !important;
}

.circular-widget .ant-list-item-action {
  margin-left: 12px !important;
}

.site-page-header{
  padding:0px !important;
}
