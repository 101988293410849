/*Header style*/
.logo {
  /*background-color: rgb(2 21 41 / 28%) !important;*/
  border-bottom: 2px solid #ffffff54;
  margin: auto;
  display: block;
}

/*Drawer Style*/
.sider-logo {
  max-width: 100%;
  min-width: 50%;
  padding: 20px;
}

/*auth base*/

.authMainLayout {
  min-height: 100vh !important;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(73, 166, 130, 0.8757878151260504) 0%,
    rgba(6, 55, 123, 0.8897934173669468) 66%
  ) !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  padding-top: 15vh;
}

.authContainer {
  background-color: white;
  /*z-index: 99999;*/
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  border-radius: 8px;
  width: 33%;
  padding: 30px;
}

.authLogoContainer {
  text-align: center;
  /*padding: 15px;*/
}

.authLogo {
  max-width: 90%;
  min-width: 15%;
  max-height: 200px;
  margin: auto;
}

.btnFloatRight {
  float: right;
}

.auth-type-title {
  text-align: center;
  margin: 10px;
}

/*mobile View*/
@media (max-width: 575px) {
  .authMainLayout {
    /*background-color: white !important;*/
    padding: 15px;
  }

  .authContainer {
    width: 100%;
    margin-top: 0px;
    box-shadow: none !important;
    position: absolute;
    bottom: 0;
  }

  .btnFloatRight {
    float: left;
  }
}

*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #5a5a5a;
}
